<template>
  <div class="clearfix">
    <el-form
      ref="VIPFormRef"
      :rules="VIPFormRules"
      :model="userInfo"
      label-width="150px"
      size="mini"
    >
      <div class="form">
        <el-form-item label="简称：">
          <span class="F14 co_7474">{{ userInfo.nickName }}</span>
        </el-form-item>
        <el-form-item label="联系人姓名：" prop="name">
          <div class="nicheng">
            <el-input v-model="userInfo.name" :disabled="this.userInfo.userType === '1'"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <el-radio-group v-model="userInfo.sex">
            <!-- 男为1，女为2 -->
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日：" prop="birthday">
          <el-date-picker
            v-model="userInfo.birthday"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="银行卡号：" prop="bankAccount">
          <div class="nicheng">
            <el-input v-model="userInfo.bankAccount" :disabled="!(userInfo.userType === '1')"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="开户行：" prop="openBank">
          <div class="nicheng">
            <el-input v-model="userInfo.openBank" :disabled="!(userInfo.userType === '1')"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="btn Ptp30">
            <el-button
              style="font-weight: bold; width: 150px"
              type="primary"
              @click="submitForm('VIPFormRef')"
              >确认提交</el-button
            >
          </div>
        </el-form-item>
      </div>
<!--      <div class="userHead">-->
<!--        <el-form-item prop="headImage">-->
<!--          <one-upload-->
<!--            moduleType="06"-->
<!--            :imgField="userInfo.headImage"-->
<!--            @imgUrl="getHeadImage"-->
<!--          ></one-upload>-->
<!--        </el-form-item>-->
<!--      </div>-->
    </el-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { updateUser } from "@/api/user";
import oneUpload from "./upload/oneUpload.vue";
export default {
  // components: { oneUpload },
  data() {
    return {
      VIPform: {
        nickName: "",
        name: "",
        sex: "",
        birthday: "",
        bankAccount: "",
        openBank: "",
      },
      headImage: "",
      VIPFormRules: {
        // name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        // sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        // birthday: [
        //   {
        //     required: true,
        //     message: "请选择生日日期",
        //     trigger: "change",
        //   },
        // ],
        name:[],
        headImage: [
          { required: true, message: "请上传头像", trigger: "change" },
        ],
        bankAccount: [],
        openBank: []
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      //   token: {},
    };
  },
  created() {
    this.getHeadImage();
    if(this.userInfo.userType !== '1'){
      this.VIPFormRules.name.push({ required: true, message: "请输入真实姓名", trigger: "blur" })
    }
    if(this.userInfo.userType == "1" && this.userInfo.roleType == "1") {
      // 供应商 自然人
      this.VIPFormRules.bankAccount.push({ required: true, message: "请输入银行卡号", trigger: "blur" })
      this.VIPFormRules.openBank.push({ required: true, message: "请输入开户行", trigger: "blur" })
    }
  },
  methods: {
    getHeadImage(val) {
      this.userInfo.headImage = val;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateUser(this.userInfo).then((res) => {
            if (res.success) {
              console.log(res.data);
              this.$message.success(res.msg);
              this.$store.dispatch("GetLoginUserInfoByToken");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    //根据token获取用户信息并且更新缓存
    this.$store.dispatch("GetLoginUserInfoByToken");
  },
};
</script>
<style lang="scss" scoped>
.nicheng {
  width: 220px;
}
.form {
  float: left;
  width: 600px;
  padding: 15px 20px 0px 20px;
  .btn {
    text-align: right;
  }
}
.userHead {
  float: right;
}
</style>
