<template>
  <div id="information">
    <certification-content>
      <div slot="menu">
        <div>会员信息</div>
      </div>
      <div slot="title">
        <div>基本信息</div>
      </div>
      <div slot="information">
          <information-form></information-form>
      </div>
    </certification-content>
  </div>
</template>
<script>
import CertificationContent from "@/components/certificationContent.vue";
import InformationForm from '../../../../components/informationForm.vue';

export default {
  components: { CertificationContent, InformationForm },
  name: "Information",
  data() {
    return {
      
    };
  },
  
};
</script>
<style scoped lang="scss">

</style>