<template>
  <div class="certificationcontent Pbm100">
      <el-container class="wrap">
        <el-header>
          <div class="title F16 orange bg_fff Plf20 Ptp15 Pbm15">
            <slot name="menu">
             <!--  账号认证<span class="main-text">（企业）</span> -->
            </slot>
          </div>
        </el-header>
        <el-main class="Mtp20">
          <div class="container bg_fff">
            <div class="content">
              <div class="bg_f4f4 F16 Line30 Plf20 Ptp15 Pbm15" style="font-weight:bold">
                <slot name="title">
                  <!-- 法人信息 -->
                </slot>
              </div>
              <div class="form">
                <div>
                  <slot name="information">
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
  </div>
</template>
<script>

export default {
  components: {

  },
  name: 'Certificationcontent',
  data() {
    return {
      
    }
  },
  methods: {
      handleChange(file, fileList) {
        this.certificationForm.license = fileList.slice(-3);
      }
  }
}
</script>
<style scoped lang="scss">
.certificationcontent {
  margin-top: 25px;
      .el-header {
        padding: 0px !important;
      height: 25px !important;
    }
    .el-main {
      padding: 20px 0px !important;
      .container {
        padding: 20px;
        .content {
          border: 1px solid #f4f4f4;
          .form {
            padding: 20px 40px 66px 0;
          }
        }
      }
  }
}

</style>